export interface Auth {
  id?: string;
  roles: string[];
  attested?: boolean;
  disabled?: boolean;
  global_name_search?: boolean;
  user?: string;
  new_user?: boolean;
  adjudicator?: string;
  super_user?: boolean;
  token?: string;
}

export class RoleClient {
  api: string;

  constructor(api: string) {
    this.api = api;
  }

  addRole(role: Auth) {
    return fetch(`${this.api}/roles.json`, {
      method: "POST",
      //cors rejection other on rails app
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(role),
    });
  }
 
  fetchRoles(email:string, token: string, admin:string) {
    const param=  new URLSearchParams({
      user: email,
      token: token,
      adjudicator: admin
    });
    return fetch(`${this.api}/roles.json?${param}`, {
      credentials: "include",
    });
  }

  getToken(email: string, payload:string){
    const content = {
      user: email,
      payload: payload
    }
    // console.log("getToken: " + content)
    return fetch(`${this.api}/login`, {
      method: "POST",
      //cors rejection other on rails app
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(content),
    });

    // const param=  new URLSearchParams({
    //   payload: payload
    // });
    // return fetch(`${this.api}/token?${param}`, {
    //   credentials: "include",
    // });
  }
}
