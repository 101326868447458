export interface Todo {
  id?: string;
  description: string;
  checked: boolean;
  user?: string;
}

export type Todos = Todo[];

export class TodoClient {
  api: string;

  constructor(api: string) {
    this.api = api;
  }

  addTodo(todo: Todo) {
    return fetch(`${this.api}/todo`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(todo),
    });
  }

  listTodos(user:string) {
    const param=  new URLSearchParams({
      user: user
    });
    return fetch(`${this.api}/todo?${param}`, {
      credentials: "include",
    });
  }

  patchTodo(id: string, checked: boolean) {
    return fetch(`${this.api}/todo/${id}`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({checked}),
    });
  }

  deleteTodo(id: string) {
    return fetch(`${this.api}/todo/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  }
}
